import React from 'react';
import './App.css';
import Page from './templates/Page.js';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<Page type="home" />} />
        <Route path="/scrapbook" element={<Page type="scrapbook" />} />
      </Routes>
    </Router>
  );
}
